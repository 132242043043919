import Product from "../Shared/Product";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { useContext } from "react";
import "./HomeNewArrivals.css";
import { Link } from "react-router-dom";

const HomeNewArrivals = () => {
  const { summerMakeup } = useContext(ThemeContext);

  const products = summerMakeup?.slice(0, 4);
  return (
    <div className="overflow-hidden">
      <div className="relative">
        <div
          className={`flex flex-col px-[10px] items-center sm:px-[20px] lg:px-[0px] lg:w-[90%] mx-auto ${
            window.innerWidth >= 1920 ? "2xl:w-[65%]" : "2xl:w-[80%]"
          }`}
        >
          <h1 className="text-center text-xl text-accent font-bold mt-[50px] sm:text-2xl">
          New Arrivals
          </h1>
          <div className="w-[100px] mx-auto h-1 bg-accent my-6 mb-10"></div>

            {/* <h1 className="text-center text-xl text-accent font-bold sm:text-xl mb-10 mt-4">
              Best Natural Skincare Solution in Bangladesh
            </h1> */}
          <div className="grid grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-6 featured-collections ">
            {products?.map((product) => (
              <Product product={product}></Product>
            ))}
          </div>
          <Link
            to="/newarrivals"
            className="text-white bg-accent px-10 rounded sm:mt-20 py-2 hover:bg-secondary ease-in-out duration-200 font-bold sm:my-10 "
          >
            View More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeNewArrivals;
